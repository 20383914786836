import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo, TitleUnderlineThree } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"

const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Enrutamientos inteligentes",
      description: "Implementa enrutamientos inteligentes para asegurar que las llamadas sean dirigidas al agente más adecuado basado en habilidades específicas y disponibilidad. De este modo, permites que tus agentes atiendan a más usuarios.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Escalamiento de operaciones",
      description: "Facilita el escalamiento de tus operaciones, adaptando rápidamente tu capacidad para manejar volúmenes de llamadas. Contamos con una infraestructura cloud que se adapta a tus campañas en todo momento.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Seguridad en tus operaciones",
      description: "Nos aseguramos de preservar todas tus conversaciones telefónicas y los datos que se vayan a generar en tus campañas mediante la protección contra DDoS y certificaciones como ISO 27001, SOC 2 y GDPR.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Escucha y audita tus llamadas en tiempo real",
      description: "Monitoriza y audita las llamadas en tiempo real para asegurar la calidad y el cumplimiento de tus campañas. Además, permite a los supervisores intervenir cuando sea necesario, ofreciendo una capa adicional de soporte y formación para los agentes.",
      image:imageThreeMobile ,
      background: "#FBE6E5"
    },
  ]

  return (
    <section className="container-experience-voiceApi">
      <p className="container-experience-voiceApi-title">
      Garantizamos que cada interacción sea una oportunidad para <TitleUnderlineOne underline="fortalecer " /> <TitleUnderlineTwo underline="relaciones y" /> <TitleUnderlineThree underline="cerrar ventas" />
      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsTwo">
        {/* columna */}
        <div className="container-experience-voiceApi-cardsTwo-cards-column">
          <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
            <section style={{padding: "0px 32px" }}>
              <p className="container-experience-voiceApi-cardsTwo-card-title">Enrutamientos inteligentes</p>
              <p className="container-experience-voiceApi-cardsTwo-card-description">
                Implementa enrutamientos inteligentes para asegurar que las llamadas sean dirigidas al agente más adecuado basado en habilidades específicas y disponibilidad. De este modo, permites que tus agentes atiendan a más usuarios.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 30px 0px 0px" }}>
          <section style={{ padding: "0px 0px 0px 32px" }} >
            <p className="container-experience-voiceApi-cardsTwo-card-title">Escalamiento de operaciones</p>
            <p className="container-experience-voiceApi-cardsTwo-card-description">
              Facilita el escalamiento de tus operaciones, adaptando rápidamente tu capacidad para manejar volúmenes de llamadas. Contamos con una infraestructura cloud que se adapta a tus campañas en todo momento.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-voiceApi-cardsOne-card-title">Seguridad en tus operaciones</p>
            <p className="container-experience-voiceApi-cardsOne-card-description">
              Nos aseguramos de preservar todas tus conversaciones telefónicas y los datos que se vayan a generar en tus campañas mediante la protección contra DDoS y certificaciones como ISO 27001, SOC 2 y GDPR.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsOne-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-voiceApi-cardsOne-column">
          {/* columna */}
          <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#EEEAFB" }}>
            <section>
              <p className="container-experience-voiceApi-cardsOne-card-title">Escucha y audita tus llamadas en tiempo real</p>
              <p className="container-experience-voiceApi-cardsOne-card-description">
                Monitoriza y audita las llamadas en tiempo real para asegurar la calidad y el cumplimiento de tus campañas. Además, permite a los supervisores intervenir cuando sea necesario, ofreciendo una capa adicional de soporte y formación para los agentes.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsOne-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      <div className="container-experience-voiceApi-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-voiceApi-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-voiceApi-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-voiceApi-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-voiceApi-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
